import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { ThunkAction } from "redux-thunk";
import {
  configureStore,
  combineReducers,
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { orgsApi } from "./services/orgs";
import { usersApi } from "./services/users";
import { projectsApi } from "./services/projects";
import { itemsApi } from "./services/items";
import { catalogUploadApi } from "./services/catalogUpload";
import { permissionsApi } from "./services/permissions";
import { apiKeysApi } from "./services/apiKeys";
import { locationsApi } from "./services/locations";
import { environmentsApi } from "./services/environments";
import { sharedAnchorsApi } from "./services/sharedAnchors";
import { occlusionMeshesApi } from "./services/occlusionMeshes";
import { sharedLinksApi } from "./services/sharedLinks";
import { assetsApi } from "./services/assets";
import { hyperionWebBuildsApi } from "./services/hyperionWebBuilds";
import { settingsApi } from "./services/settings";
import { tiersApi } from "./services/tier";
import Logger from "../lib/logger";
import {experiencesApi} from "./services/experiences";
import {SDKApi} from "./services/sdk";
import {zonesApi} from "./services/zones";
import {activatorsApi} from "./services/activators";
import {schedulesApi} from "./services/schedules";
import {spatialCapturesApi} from "./services/spatialCaptures";

const rootReducer = combineReducers({
  [orgsApi.reducerPath]: orgsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [itemsApi.reducerPath]: itemsApi.reducer,
  [catalogUploadApi.reducerPath]: catalogUploadApi.reducer,
  [apiKeysApi.reducerPath]: apiKeysApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [environmentsApi.reducerPath]: environmentsApi.reducer,
  [sharedAnchorsApi.reducerPath]: sharedAnchorsApi.reducer,
  [occlusionMeshesApi.reducerPath]: occlusionMeshesApi.reducer,
  [sharedLinksApi.reducerPath]: sharedLinksApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [hyperionWebBuildsApi.reducerPath]: hyperionWebBuildsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [tiersApi.reducerPath]: tiersApi.reducer,
  [experiencesApi.reducerPath]: experiencesApi.reducer,
  [SDKApi.reducerPath]: SDKApi.reducer,
  [zonesApi.reducerPath]: zonesApi.reducer,
  [activatorsApi.reducerPath]: activatorsApi.reducer,
  [schedulesApi.reducerPath]: schedulesApi.reducer,
  [spatialCapturesApi.reducerPath]: spatialCapturesApi.reducer,
});

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      Logger.error({ prefix: "rtkQueryErrorLogger.rejected", msg: action });
    }

    return next(action);
  };

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      orgsApi.middleware,
      usersApi.middleware,
      projectsApi.middleware,
      itemsApi.middleware,
      catalogUploadApi.middleware,
      permissionsApi.middleware,
      apiKeysApi.middleware,
      locationsApi.middleware,
      environmentsApi.middleware,
      sharedAnchorsApi.middleware,
      occlusionMeshesApi.middleware,
      sharedLinksApi.middleware,
      assetsApi.middleware,
      hyperionWebBuildsApi.middleware,
      settingsApi.middleware,
      tiersApi.middleware,
      experiencesApi.middleware,
      SDKApi.middleware,
      zonesApi.middleware,
      activatorsApi.middleware,
      schedulesApi.middleware,
      spatialCapturesApi.middleware,
      rtkQueryErrorLogger
    ),
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
