import { FC, useEffect, ReactNode } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";
import { experimentalStyled } from "@mui/material/styles";

import { useGetUserByIdQuery } from "../../store/services/users";
import { auth0Config } from "../../config";
import DashboardNavbar from "./DashboardNavbar";
// import DashboardSidebar from "./DashboardSidebar";
import LoadingScreen from "../LoadingScreen";
import SplashScreen from "../SplashScreen";
import { ApiResponseError } from "../../types/response";
import usePermissions from "../../hooks/usePermissions";
import isOnShareSubdomain from "../../utils/isOnShareSubdomain";
import { setLocalStorage } from "../../lib/localStorage";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexGrow: 1,
  flexDirection: 'column',
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  // [theme.breakpoints.up("lg")]: {
  //   paddingLeft: "280px",
  // },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const UserLoadingWrapper: FC<{ userId: string; children?: ReactNode }> = (
  props
) => {
  const navigate = useNavigate();
  const {
    initiatePermissions,
    state: { ids },
  } = usePermissions();

  const { data, error } = useGetUserByIdQuery({
    id: props.userId,
    include: ["permissions"],
  });

  useEffect(() => {
    if (ids == null && data?.data != null && data?.meta?.permissions != null) {
      initiatePermissions({
        ids: {
          user: data.data.id,
          org: data.data.orgId,
        },
        permissionsMap: data.meta.permissions,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatePermissions, ids, data]);

  useEffect(() => {
    if (error != null) {
      let err = error as ApiResponseError;
      navigate(
        `/${err.status && typeof err.status === "number" ? err.status : 500}`
      );
    }
  }, [error, navigate]);

  if (data?.data == null || data?.meta == null) return <SplashScreen />;

  return (
    <>
      {!data?.data && <LoadingScreen />}
      {ids != null && <DashboardNavbar user={data.data} />}
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            {props.children || <Outlet />}
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </>
  );
};

const DashboardLayout: FC<{ children?: ReactNode }> = (props) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (isAuthenticated) {
    getAccessTokenSilently().then((token) =>
      setLocalStorage({ key: "accessToken", value: token })
    );
  }

  const isShareSubdomain = isOnShareSubdomain();
  useEffect(() => {
    if (isShareSubdomain) {
      navigate(`/forbidden`);
    }
  }, [isShareSubdomain, navigate]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="A web portal for Illumix products. Dashboard to manage projects and control analytics."
        />
      </Helmet>

      <DashboardLayoutRoot>
        {user && (
          <UserLoadingWrapper userId={user[auth0Config.illumix_key]}>
            {props.children}
          </UserLoadingWrapper>
        )}
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayout;
