import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { Location } from "../../types/location";
import { ApiResponse } from "../../types/response";
import { axiosBaseQuery } from "./fetch";

const TAG = "Locations";
const LIST = "LIST";

export const locationsApi = createApi({
  reducerPath: "locationsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getLocationById: builder.query<ApiResponse<Location>, { id: string }>({
      query: ({ id }) => ({
        url: `venue/locations/${id}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getLocations: builder.query<
      ApiResponse<Location[]>,
      { projectId?: string }
    >({
      query: ({ projectId }) => {
        let url = `venue/locations`;
        if (projectId) url += `?projectId=${projectId}`;
        return { url, method: "GET" };
      },
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createLocation: builder.mutation<
      ApiResponse<Location>,
      {
        data: Omit<
          Location,
          "id" | "createdAt" | "updatedAt" | "disabled" | "activeEnvironmentId"
        >;
      }
    >({
      query: ({ data }) => ({
        url: `venue/locations`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateLocation: builder.mutation<
      ApiResponse<Location>,
      { id: string; data: Partial<Location> }
    >({
      query: ({ id, data }) => ({
        url: `venue/locations/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteLocation: builder.mutation<ApiResponse<Location>, { id: string }>({
      query: ({ id }) => ({
        url: `venue/locations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetLocationByIdQuery,
  useGetLocationsQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationsApi;
