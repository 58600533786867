import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Error403: FC<{ hideButton?: boolean }> = (props) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        minHeight: "100%",
        px: 3,
        py: "80px",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          color="textPrimary"
          variant={mobileDevice ? "h4" : "h1"}
        >
          403: Forbidden
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 0.5 }}
          variant="subtitle2"
        >
          Sorry about that, it looks like you don't have the credentials to
          access this page.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Box
            alt=""
            component="img"
            src={`/static/error/error401_${theme.palette.mode}.svg`}
            sx={{
              height: "auto",
              maxWidth: "100%",
              width: 400,
            }}
          />
        </Box>
        {!props.hideButton && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              to="/home"
              variant="outlined"
            >
              Back to Home
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Error403;
