import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Link, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton: FC = (props) => {
  const { isLoading, user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {user != null ? (
        <Link
          // color="textSecondary"
          component={RouterLink}
          to="/"
          underline="none"
          variant="body1"
        >
          <Button
            variant="contained"
            color="secondary"
            component="button"
            size="small"
          >
            Dashboard
          </Button>
        </Link>
      ) : (
        <LoadingButton
          loading={isLoading}
          onClick={() => navigate("/home/authorize")}
          variant="contained"
          color="secondary"
          component="button"
          size="small"
        >
          {isAuthenticated ? "Dashboard" : "Login"}
        </LoadingButton>
      )}
    </Box>
  );
};

export default LoginButton;
