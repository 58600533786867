import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import {
  PartialSharedLink,
  SharedLink,
  SharedLinkFormValues,
} from "../../types/sharedLink";
import { axiosBaseQuery } from "./fetch";

const TAG = "SharedLinks";
const LIST = "LIST";

export const sharedLinksApi = createApi({
  reducerPath: "sharedLinksApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getSharedLinkById: builder.query<ApiResponse<SharedLink>, { id: string }>({
      query: ({ id }) => ({
        url: `try_on/shared_links/${id}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getSharedLinks: builder.query<
      ApiResponse<SharedLink[]>,
      { itemId?: string; projectId?: string }
    >({
      query: ({ itemId, projectId }) => {
        let url = `try_on/shared_links`;
        if (projectId) {
          url += `?projectId=${projectId}`;
        } else if (itemId) {
          url += `?itemId=${itemId}`;
        }
        return { url, method: "GET" };
      },
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createSharedLink: builder.mutation<
      ApiResponse<SharedLink>,
      {
        data: SharedLinkFormValues & { itemId: string; projectId: string };
      }
    >({
      query: ({ data }) => ({
        url: `try_on/shared_links`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateSharedLink: builder.mutation<
      ApiResponse<SharedLink>,
      { id: string; data: PartialSharedLink }
    >({
      query: ({ id, data }) => ({
        url: `try_on/shared_links/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteSharedLink: builder.mutation<ApiResponse<SharedLink>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `try_on/shared_links/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, err) =>
          result?.data ? [{ type: TAG, id: LIST }] : [],
      }
    ),
  }),
});

export const {
  useGetSharedLinkByIdQuery,
  useGetSharedLinksQuery,
  useCreateSharedLinkMutation,
  useUpdateSharedLinkMutation,
  useDeleteSharedLinkMutation,
} = sharedLinksApi;
