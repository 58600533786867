import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { axiosBaseQuery } from "./fetch";
import {ApiResponse} from "../../types/response";
import {VenueV2Zone} from "@illumix-inc/types.apollo-monorepo";

const TAG = "Zones";
const LIST = "LIST";

export const zonesApi = createApi({
  reducerPath: "zonesApi",
  tagTypes: [TAG],
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/v2/venue/`,
  }),
  endpoints: (builder) => ({
    getZoneById: builder.query<ApiResponse<VenueV2Zone>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `zones/${id}`,
          method: "GET",
        }),
        providesTags: (result, err) =>
          result?.data ? [{ type: TAG, id: result.data.id }] : [],
      }
    ),

    getZones: builder.query<
      ApiResponse<VenueV2Zone[]>,
      { projectId?: string }
    >({
      query: ({ projectId }) => ({
        url: `zones?projectId=${projectId}`,
        method: 'GET',
      }),
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
            { type: TAG, id: LIST },
            ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
          ]
          : [],
    }),

    createZone: builder.mutation<
      ApiResponse<VenueV2Zone>,
      {
        data: Partial<VenueV2Zone>;
      }
    >({
      query: ({ data }) => ({
        url: `zones`,
        method: "POST",
        // HARDCODED type until we support polygon type
        data: {...data, type: 'radius'},
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateZone: builder.mutation<
      ApiResponse<VenueV2Zone>,
      { id: string; data: Partial<VenueV2Zone> }
    >({
      query: ({ id, data }) => ({
        url: `zones/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
            { type: TAG, id: result.data.id },
            { type: TAG, id: LIST },
          ]
          : [],
    }),

  }),
});

export const {
  useCreateZoneMutation,
  useGetZonesQuery,
  useGetZoneByIdQuery,
  useUpdateZoneMutation,
} = zonesApi;
