import {FC, useEffect, ReactNode, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { experimentalStyled } from "@mui/material/styles";

import { useGetUserByIdQuery } from "../../store/services/users";
import { auth0Config } from "../../config";
import DashboardNavbarV2 from "./DashboardNavbarV2";
import SplashScreen from "../SplashScreen";
import { ApiResponseError } from "../../types/response";
import usePermissions from "../../hooks/usePermissions";
import isOnShareSubdomain from "../../utils/isOnShareSubdomain";
import { setLocalStorage } from "../../lib/localStorage";
import {Box, Hidden} from "@mui/material";
import DashboardSidebarV2 from "./DashboardSidebarV2";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexGrow: 1,
  flexDirection: 'column',
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const UserLoadingWrapper: FC<{ userId: string; children?: ReactNode }> = (
  props
) => {
  const navigate = useNavigate();
  const {
    initiatePermissions,
    state: { ids },
  } = usePermissions();
  const { paramOrgId, paramProjectId } = useParams();

  const { data, error } = useGetUserByIdQuery({
    id: props.userId,
    include: ["permissions"],
  });

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(true);

  useEffect(() => {
    if (ids == null && data?.data != null && data?.meta?.permissions != null) {
      initiatePermissions({
        ids: {
          user: data.data.id,
          org: data.data.orgId,
        },
        permissionsMap: data.meta.permissions,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatePermissions, ids, data]);

  useEffect(() => {
    if (error != null) {
      let err = error as ApiResponseError;
      navigate(
        `/${err.status && typeof err.status === "number" ? err.status : 500}`
      );
    }
  }, [error, navigate]);

  if (data?.data == null || data?.meta == null) return <SplashScreen />;

  const DASHBOARD_WIDTH = '280px';

  return (
    <>
      {ids != null && <DashboardNavbarV2 setIsSidebarMobileOpen={setIsSidebarMobileOpen} user={data.data} orgId={paramOrgId} />}
      {ids != null && paramProjectId &&<DashboardSidebarV2
        orgId={paramOrgId}
        user={data.data}
        width={DASHBOARD_WIDTH}
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />}
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Hidden lgDown>
              <Box sx={{minWidth: DASHBOARD_WIDTH}}></Box>
            </Hidden>
            <Box sx={{flexGrow: 1}}>
              {/* different key per project forces an refresh for the outlet but NOT the entire app when new project is selected */}
              {props.children || <Outlet key={paramProjectId} />}
            </Box>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </>
  );
};

const DashboardLayoutV2: FC<{ children?: ReactNode }> = (props) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();


  if (isAuthenticated) {
    getAccessTokenSilently().then((token) =>
      setLocalStorage({ key: "accessToken", value: token })
    );
  }
  const isShareSubdomain = isOnShareSubdomain();
  useEffect(() => {
    if (isShareSubdomain) {
      navigate(`/forbidden`);
    }
  }, [isShareSubdomain, navigate]);

  return (
    <>
      <DashboardLayoutRoot>
        {user && (
          <UserLoadingWrapper userId={user[auth0Config.illumix_key]}>
            {props.children}
          </UserLoadingWrapper>
        )}
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayoutV2;
