import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { axiosBaseQuery } from "./fetch";
import {ApiResponse} from "../../types/response";
import {VenueV2Activator} from "@illumix-inc/types.apollo-monorepo";
import {Activator} from "../../types/venueV2Activator";

const TAG = "Activators";
const LIST = "LIST";

type Optionals = {
  projectId?: string;
  experienceId?: string;
  zoneId?: string;
  scheduleId?: string;
} & ({
  projectId: string;
} | {
  experienceId: string;
} | {
  zoneId: string;
} | {
  scheduleId: string;
})

export const activatorsApi = createApi({
  reducerPath: "activatorsApi",
  tagTypes: [TAG],
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/v2/venue/`,
  }),
  endpoints: (builder) => ({
    getActivatorById: builder.query<ApiResponse<Activator>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `activators/${id}`,
          method: "GET",
        }),
        providesTags: (result, err) =>
          result?.data ? [{ type: TAG, id: result.data.id }] : [],
      }
    ),

    getActivators: builder.query<
      ApiResponse<Activator[]>,
      Optionals
    >({
      query: (query) => ({
        url: `activators?${Object.keys(query).map((key) => `${key}=${(query as any)[key]}`).join('&')}`,
        method: 'GET',
      }),
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
            { type: TAG, id: LIST },
            ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
          ]
          : [],
    }),

    createActivator: builder.mutation<
      ApiResponse<VenueV2Activator>,
      {
        data: Partial<VenueV2Activator>;
      }
    >({
      query: ({ data }) => ({
        url: `activators`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateActivator: builder.mutation<
      ApiResponse<VenueV2Activator>,
      { id: string; data: Partial<VenueV2Activator> }
    >({
      query: ({ id, data }) => ({
        url: `activators/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
            { type: TAG, id: result.data.id },
            { type: TAG, id: LIST },
          ]
          : [],
    }),

  }),
});

export const {
  useGetActivatorsQuery,
  useUpdateActivatorMutation,
  useCreateActivatorMutation,
  useGetActivatorByIdQuery,
} = activatorsApi;
