import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import {
  Item,
  ItemExportResponse,
  ItemImportRequest,
  ItemImportResponse,
} from "../../types/item";
import { ApiResponse } from "../../types/response";
import { Setting } from "../../types/setting";
import createQueryParams from "../../utils/createQueryParams";

const TAG = "Items";
const LIST = "LIST";

export const itemsApi = createApi({
  reducerPath: "itemsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getItemById: builder.query<
      ApiResponse<Item, { settings: Setting }>,
      { id: string; include?: "settings"[] }
    >({
      query: ({ id, include = [] }) => ({
        url: `try_on/items/${id}${createQueryParams({
          include: include.join(","),
        })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getItems: builder.query<
      ApiResponse<Item[]>,
      { projectId?: string; orgId?: string }
    >({
      query: ({ projectId, orgId }) => ({
        url: `try_on/items${createQueryParams({
          projectId,
          orgId,
        })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
            { type: TAG, id: LIST },
            ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
          ]
          : [],
    }),

    createItem: builder.mutation<
      ApiResponse<Item>,
      {
        data: Omit<Item, "id" | "createdAt" | "updatedAt" | "thumbnail">;
      }
    >({
      query: ({ data }) => ({
        url: `try_on/items`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateItem: builder.mutation<
      ApiResponse<Item>,
      { id: string; data: Partial<Item> }
    >({
      query: ({ id, data }) => ({
        url: `try_on/items/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
            { type: TAG, id: LIST },
            { type: TAG, id: result.data.id },
          ]
          : [],
    }),

    deleteItem: builder.mutation<ApiResponse<Item>, { id: string }>({
      query: ({ id }) => ({
        url: `try_on/items/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    deleteItems: builder.mutation<
      ApiResponse<Item[]>,
      { projectId: string; itemIds?: string[] }
    >({
      query: ({ projectId, itemIds }) => ({
        url: `try_on/items?projectId=${projectId}${itemIds ? "&itemIds=" + itemIds.join(",") : ""
          }`,
        method: "DELETE",
      }),
    }),

    exportItems: builder.query<
      ApiResponse<ItemExportResponse>,
      { projectId: string; itemIds?: string[] }
    >({
      query: ({ projectId, itemIds }) => ({
        url: `try_on/items?action=export&projectId=${projectId}${itemIds ? "&itemIds=" + itemIds.join(",") : ""
          }`,
        method: "GET",
      }),
    }),

    importItems: builder.mutation<
      ApiResponse<ItemImportResponse>,
      { data: ItemImportRequest }
    >({
      query: ({ data }) => ({
        url: `try_on/items?action=import`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetItemByIdQuery,
  useLazyGetItemByIdQuery,
  useGetItemsQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useDeleteItemsMutation,
  useExportItemsQuery,
  useImportItemsMutation,
} = itemsApi;
