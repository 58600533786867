import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { axiosBaseQuery } from "./fetch";
import {SpatialCapture} from "../../types/TEMP_TYPES/spatialCapture";
import {ApiResponse} from "../../types/response";

const TAG = "SpatialCaptures";
const LIST = "LIST";

export const spatialCapturesApi = createApi({
  reducerPath: "spatialCapturesApi",
  tagTypes: [TAG],
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/v2/venue/`,
  }),
  endpoints: (builder) => ({
    getSpatialCaptureById: builder.query<ApiResponse<SpatialCapture>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `spatial_captures/${id}`,
          method: "GET",
        }),
        providesTags: (result, err) =>
          result?.data ? [{ type: TAG, id: result.data.id }] : [],
      }
    ),

    getSpatialCaptures: builder.query<
      ApiResponse<SpatialCapture[]>,
      { projectId?: string }
    >({
      query: ({ projectId }) => ({
        url: `spatial_captures?projectId=${projectId}`,
        method: 'GET',
      }),
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
            { type: TAG, id: LIST },
            ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
          ]
          : [],
    }),

    createSpatialCapture: builder.mutation<
      ApiResponse<SpatialCapture>,
      {
        data: SpatialCapture;
      }
    >({
      query: ({ data }) => ({
        url: `spatial_captures`,
        method: "POST",
        data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateSpatialCapture: builder.mutation<
      ApiResponse<SpatialCapture>,
      { id: string; data: Partial<SpatialCapture> }
    >({
      query: ({ id, data }) => ({
        url: `spatial_captures/${id}`,
        method: "PUT",
          data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
            { type: TAG, id: result.data.id },
            { type: TAG, id: LIST },
          ]
          : [],
    }),

  }),
});

export const {
  useGetSpatialCapturesQuery,
  useUpdateSpatialCaptureMutation,
  useCreateSpatialCaptureMutation,
  useGetSpatialCaptureByIdQuery,
} = spatialCapturesApi;
