import { LazyExoticComponent, FC, Suspense, lazy } from "react";
import type { RouteObject } from "react-router";

import AuthGuard from "./components/AuthGuard";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import MainLayout from "./components/MainLayout";
import Error403 from "./components/errors/Error403";
import DashboardLayoutV2 from "./components/dashboard-v2/DashboardLayoutV2";

const Loadable = (Component: LazyExoticComponent<FC<any>>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Error components
const Error401 = Loadable(lazy(() => import("./components/errors/Error401")));
const Error404 = Loadable(lazy(() => import("./components/errors/Error404")));
const Error500 = Loadable(lazy(() => import("./components/errors/Error500")));

const Contact = Loadable(lazy(() => import("./components/Contact")));
const Privacy = Loadable(lazy(() => import("./components/Privacy")));
const Terms = Loadable(lazy(() => import("./components/Terms")));

// Auth0 Authenticate
const Authorize = Loadable(lazy(() => import("./components/Authorize")));

// Try On SharedLink
const SharedLink = Loadable(lazy(() => import("./pages/SharedLink")));

// Pages
const Home = Loadable(lazy(() => import("./pages/Home")));
const Blank = Loadable(lazy(() => import("./pages/Blank")));

// Dashboard pages
const Redirector = Loadable(lazy(() => import("./pages/dashboard/Redirector")));
const Users = Loadable(lazy(() => import("./pages/dashboard/Users")));
const CredentialViewer = Loadable(lazy(() => import("./pages/dashboard/CredentialViewer")));
const Organization = Loadable(
  lazy(() => import("./pages/dashboard/Organization"))
);
const OrganizationList = Loadable(
  lazy(() => import("./pages/dashboard/OrganizationList"))
);
const Project = Loadable(lazy(() => import("./pages/dashboard/Project")));

const Product = Loadable(lazy(() => import("./pages/dashboard/Product")));

// Venue v2
const ExperienceManager = Loadable(lazy(() => import("./pages/venuev2/ExperienceManager")));
const ExperienceList = Loadable(lazy(() => import("./pages/venuev2/ExperienceList")));
const ZoneList = Loadable(lazy(() => import("./pages/venuev2/ZoneList")));
const ScheduleList = Loadable(lazy(() => import("./pages/venuev2/ScheduleList")));
const SpatialCaptureList = Loadable(lazy(() => import("./pages/venuev2/SpatialCaptureList")));

const routes: RouteObject[] = [
  {
    path: "t",
    children: [
      {
        path: "",
        element: (
          <Blank title={"Error: Forbidden | Illumix"}>
            <Error403 hideButton={true} />
          </Blank>
        ),
      },
      {
        path: ":paramSharedLinkId",
        element: <SharedLink />,
      },
    ],
  },
  {
    path: "forbidden",
    element: (
      <Blank title={"Error: Forbidden | Illumix"}>
        <Error403 hideButton={true} />
      </Blank>
    ),
  },
  {
    path: "",
    element: <Redirector />,
  },
  {
    path: "organizations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <OrganizationList />,
      },
      {
        path: "SDK",
        element: <CredentialViewer />,
      },
      {
        path: ":paramOrgId",
        children: [
          {
            path: "",
            element: <Organization />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "projects",
            children: [
              {
                path: ":paramProjectId",

                children: [
                  {
                    path: "",
                    element: <Project />,
                  },
                  {
                    path: "products",
                    children: [
                      {
                        path: ":paramProductId",
                        element: <Product />,
                      },
                    ],
                  },
                  {
                    path: "experienceManager",
                    element: (
                      <DashboardLayoutV2>
                      </DashboardLayoutV2>
                    ),
                    children: [
                      {
                        path: "overview",
                        element: <ExperienceManager />,
                      },
                      {
                        path: "experiences",
                        element: <ExperienceList />,
                      },
                      {
                        path: "users",
                        element: <Users/>,
                      },
                      {
                        path: "zones",
                        element: <ZoneList />,
                      },
                      {
                        path: "schedules",
                        element: <ScheduleList />,
                      },
                      {
                        path: "SDK",
                        element: <CredentialViewer />,
                      },
                      {
                        path: "spatialCaptures",
                        element: <SpatialCaptureList />,
                      },
                    ],
                  },
                  {
                    path: "SDK",
                    element: <CredentialViewer />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "home",
    element: (
      <MainLayout>
        <Home />
      </MainLayout>
    ),
    children: [
      {
        path: "authorize",
        element: <Authorize />,
      },
    ],
  },
  {
    path: "privacy",
    element: (
      <MainLayout>
        <Blank title={"Privacy | Illumix"}>
          <Privacy />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "terms",
    element: (
      <MainLayout>
        <Blank title={"Terms | Illumix"}>
          <Terms />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "contact",
    element: (
      <MainLayout>
        <Blank title={"Contact | Illumix"}>
          <Contact />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "401",
    element: (
      <MainLayout>
        <Blank title={"Error: Authorization Required | Illumix"}>
          <Error401 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "403",
    element: (
      <MainLayout>
        <Blank title={"Error: Forbidden | Illumix"}>
          <Error403 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "404",
    element: (
      <MainLayout>
        <Blank title={"Error: Not Found | Illumix"}>
          <Error404 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "500",
    element: (
      <MainLayout>
        <Blank title={"Error: Server Error | Illumix"}>
          <Error500 />
        </Blank>
      </MainLayout>
    ),
  },
  {
    path: "*",
    element: (
      <MainLayout>
        <Blank title={"Not Found | Illumix"}>
          <Error404 />
        </Blank>
      </MainLayout>
    ),
  },
];

export default routes;
