import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { Environment } from "../../types/environment";
import { ApiResponse } from "../../types/response";
import { axiosBaseQuery } from "./fetch";

const TAG = "Environments";
const LIST = "LIST";

export const environmentsApi = createApi({
  reducerPath: "environmentsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getEnvironmentById: builder.query<ApiResponse<Environment>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `venue/environments/${id}`,
          method: "GET",
        }),
        providesTags: (result, err) =>
          result?.data ? [{ type: TAG, id: result.data.id }] : [],
      }
    ),

    getEnvironments: builder.query<
      ApiResponse<Environment[]>,
      { projectId?: string }
    >({
      query: ({ projectId }) => {
        let url = `venue/environments`;
        if (projectId) url += `?projectId=${projectId}`;
        return { url, method: "GET" };
      },
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createEnvironment: builder.mutation<
      ApiResponse<Environment>,
      {
        data: Omit<
          Environment,
          "id" | "createdAt" | "updatedAt" | "disabled" | "projectId"
        >;
      }
    >({
      query: ({ data }) => ({
        url: `venue/environments`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateEnvironment: builder.mutation<
      ApiResponse<Environment>,
      { id: string; data: Partial<Environment> }
    >({
      query: ({ id, data }) => ({
        url: `venue/environments/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteEnvironment: builder.mutation<
      ApiResponse<Environment>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `venue/environments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetEnvironmentByIdQuery,
  useGetEnvironmentsQuery,
  useCreateEnvironmentMutation,
  useUpdateEnvironmentMutation,
  useDeleteEnvironmentMutation,
} = environmentsApi;
