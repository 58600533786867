import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { axiosBaseQuery } from "./fetch";
import {Experience} from "../../types/experience";
import {ApiResponse} from "../../types/response";

const TAG = "Experiences";
const LIST = "LIST";

export const experiencesApi = createApi({
  reducerPath: "experiencesApi",
  tagTypes: [TAG],
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/v2/venue/`,
  }),
  endpoints: (builder) => ({
    getExperienceById: builder.query<ApiResponse<Experience>, { id: string }>(
      {
        query: ({ id }) => ({
          url: `experiences/${id}`,
          method: "GET",
        }),
        providesTags: (result, err) =>
          result?.data ? [{ type: TAG, id: result.data.id }] : [],
      }
    ),

    getExperiences: builder.query<
      ApiResponse<Experience[]>,
      { projectId?: string }
    >({
      query: ({ projectId }) => ({
        url: `experiences?projectId=${projectId}`,
        method: 'GET',
      }),
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
            { type: TAG, id: LIST },
            ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
          ]
          : [],
    }),

    createExperience: builder.mutation<
      ApiResponse<Experience>,
      {
        data: Omit<
          Experience,
          "id" | "createdAt" | "updatedAt" | "disabled" | "spawnLocations"
        >;
      }
    >({
      query: ({ data }) => ({
        url: `experiences`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateExperience: builder.mutation<
      ApiResponse<Experience>,
      { id: string; data: Partial<Experience> }
    >({
      query: ({ id, data }) => ({
        url: `experiences/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
            { type: TAG, id: result.data.id },
            { type: TAG, id: LIST },
          ]
          : [],
    }),

  }),
});

export const {
  useGetExperiencesQuery,
  useUpdateExperienceMutation,
  useCreateExperienceMutation,
  useGetExperienceByIdQuery,
} = experiencesApi;
