import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import type { AppBarProps } from "@mui/material";
import { DarkMode, LightMode } from "@mui/icons-material";

import AccountPopover from "./AccountPopover";
// import LanguagePopover from "./LanguagePopover";
// import ContentSearch from "./ContentSearch";
// import NotificationsPopover from "./NotificationsPopover";
import Logo from "../common/Logo";
import { User } from "../../types/user";
import useSettings from "../../hooks/useSettings";
import { THEMES } from "../../constants";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer - 100,
}));

const DashboardNavbar: FC<
  {
    user: User;
  } & AppBarProps
> = (props) => {
  const { settings, saveSettings } = useSettings();

  return (
    <DashboardNavbarRoot>
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to={`/organizations/${props.user.orgId}`}>
          <Logo
            name={true}
            sx={{
              height: 20,
              width: "inherit",
            }}
          />
        </RouterLink>

        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />

        <Box>
          <Tooltip title="Change theme">
            <ToggleButtonGroup
              size="small"
              value={settings.theme}
              exclusive
              onChange={(e, theme) => {
                if (theme) {
                  saveSettings({
                    ...settings,
                    theme,
                  });
                }
              }}
            >
              <ToggleButton value={THEMES.LIGHT}>
                <LightMode />
              </ToggleButton>
              <ToggleButton value={THEMES.DARK}>
                <DarkMode />
              </ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>
        </Box>

        <Box sx={{ ml: 2 }}>
          <AccountPopover user={props.user} />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
