import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import {
  CatalogUpload,
  CatalogUploadWithRows,
} from "../../types/catalogUpload";

import createQueryParams from "../../utils/createQueryParams";
import { ApiResponse } from "../../types/response";

const TAG = "CatalogUploads";
const LIST = "LIST";

export const catalogUploadApi = createApi({
  reducerPath: "catalogUploadApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    createCatalogUpload: builder.mutation<
      ApiResponse<CatalogUpload>,
      { queries: { projectId: string; userId: string }; data: FormData }
    >({
      query: ({ queries, data }) => ({
        url: `try_on/catalog_uploads${createQueryParams(
          queries
        )}&fileFormat=csv`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
    exportCatalog: builder.query<Blob, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `try_on/catalog_csv?projectId=${projectId}`,
        method: "GET",
      }),
    }),
    listCatalogUpload: builder.query<
      ApiResponse<CatalogUpload[]>,
      { projectId: string; limit: Number }
    >({
      query: ({ projectId, limit }) => ({
        url: `try_on/catalog_uploads?projectId=${projectId}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getCatalogUpload: builder.query<
      ApiResponse<CatalogUploadWithRows>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `try_on/catalog_uploads/${id}?include=rows`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateCatalogUploadMutation,
  useExportCatalogQuery,
  useListCatalogUploadQuery,
  useGetCatalogUploadQuery,
} = catalogUploadApi;
