import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import {
  OcclusionMesh,
  OcclusionMeshFormValues,
} from "../../types/occlusionMesh";
import { ApiResponse } from "../../types/response";
import { axiosBaseQuery } from "./fetch";

const TAG = "OcclusionMeshes";
const LIST = "LIST";

export const occlusionMeshesApi = createApi({
  reducerPath: "OcclusionMeshesApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getOcclusionMeshById: builder.query<
      ApiResponse<OcclusionMesh>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `venue/occlusion_meshes/${id}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getOcclusionMeshes: builder.query<
      ApiResponse<OcclusionMesh[]>,
      { environmentId?: string }
    >({
      query: ({ environmentId }) => {
        let url = `venue/occlusion_meshes`;
        if (environmentId) url += `?environmentId=${environmentId}`;
        return { url, method: "GET" };
      },
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createOcclusionMesh: builder.mutation<
      ApiResponse<OcclusionMesh>,
      {
        data: OcclusionMeshFormValues;
      }
    >({
      query: ({ data }) => ({
        url: `venue/occlusion_meshes`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateOcclusionMesh: builder.mutation<
      ApiResponse<OcclusionMesh>,
      { id: string; data: Partial<OcclusionMesh> }
    >({
      query: ({ id, data }) => ({
        url: `venue/occlusion_meshes/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteOcclusionMesh: builder.mutation<
      ApiResponse<OcclusionMesh>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `venue/occlusion_meshes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetOcclusionMeshByIdQuery,
  useGetOcclusionMeshesQuery,
  useCreateOcclusionMeshMutation,
  useUpdateOcclusionMeshMutation,
  useDeleteOcclusionMeshMutation,
} = occlusionMeshesApi;
