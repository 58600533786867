import { FC, ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {getLocalStorage, setLocalStorage} from "../lib/localStorage";

const AuthGuard: FC<{ children: ReactNode }> = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const redirectPath = getLocalStorage({ key: 'post_auth_redirect'}) || undefined;
  const setRedirectPath = (value: string | null) => setLocalStorage({ key: 'post_auth_redirect', value: value});

  useEffect(() => {
    if (!isAuthenticated) {
      if (pathname !== redirectPath) {
        setRedirectPath(pathname);
      }

      navigate("/");
    }
  }, [isAuthenticated, pathname, redirectPath, navigate]);

  return <>{props.children}</>;
};

export default AuthGuard;
