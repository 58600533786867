import { useEffect } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from "@mui/material/CssBaseline";

import "./i18n";

import RTL from "./components/RTL";
import SplashScreen from "./components/SplashScreen";
import useScrollReset from "./hooks/useScrollReset";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./hooks/contexts/SettingsContext";
import { gtmConfig } from "./config";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createTheme } from "./theme";
import { ModalProvider } from "./hooks/contexts/ModalContext";
import { TourProvider } from "./hooks/contexts/TourContext";
import ShepherdTourTheme from "./shepherd/ShepherdTourTheme";

const App: FC = () => {
  const content = useRoutes(routes);
  const { isLoading } = useAuth0();
  useScrollReset();

  useEffect(() => {
    if (!gtm.initialized) {
      gtm.initialize(gtmConfig);
    }
  }, []);

  return (
    <ModalProvider>
      <TourProvider>
        <ShepherdTourTheme />

        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => (
              <ThemeProvider
                theme={createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  mode: settings.theme,
                })}
              >
                <RTL direction={settings.direction}>
                  <CssBaseline />

                  <SnackbarProvider
                    preventDuplicate
                    dense
                    maxSnack={5}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    {!isLoading ? content : <SplashScreen />}
                  </SnackbarProvider>
                </RTL>
              </ThemeProvider>
            )}
          </SettingsConsumer>
        </SettingsProvider>
      </TourProvider>
    </ModalProvider>
  );
};

export default App;
