import { ItemType } from "./types/item";
import { ProjectOption } from "./types/org";
import { UserRole } from "./types/permissions";
import { ProjectType } from "./types/project";
import * as yup from "yup";

export const ITEM_ASSET_KEYS = {
  "2D_MODEL_VIEWER": "2D_MODEL_VIEWER",
  MODEL: "MODEL",
};

export const SHARED_LINK_TEXT_ERROR_COLOR = "#7B7B7B";

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const JSON_THEMES: { [k: string]: string } = {
  light: "jse-theme-default",
  dark: "jse-theme-dark",
};

export const PROJECT_TYPE_MAPPING: Record<ProjectType, string> = {
  try_on: "Try On",
  venue: "Venue",
  venue_v2: "Venue V2",
};

export const DASHBOARD_CHANGED_ROLES: UserRole[] = [
  "owner",
  "member",
  "viewer",
];

export const ITEM_TYPE_MAPPING: Record<ItemType, string> = {
  rings: "Ring",
  vehicles: "Vehicle",
  glasses: "Glasses",
  furnitures: "Furniture",
  purses: "Purse",
  luggages: "Luggage",
  necklaces: "Necklace",
  image_tracking: "Image Track"
};

export const ITEM_TYPES_HYPERION_WEB: ItemType[] = ["rings", "glasses", "necklaces", "image_tracking"];

export const ITEM_TYPES_MODEL_VIEWER: ItemType[] = [
  "vehicles",
  "furnitures",
  "luggages",
  "purses",
];

export const ITEM_TYPES: ItemType[] = [
  ...ITEM_TYPES_HYPERION_WEB,
  ...ITEM_TYPES_MODEL_VIEWER,
];

export const FINGER_MAPPING: { [s: string]: string } = {
  thumb: "Thumb",
  index: "Index/Pointer",
  middle: "Middle",
  ring: "Ring",
  pinky: "Pinky",
};

export const FINGERS = ["thumb", "index", "middle", "ring", "pinky"];

export const RING_SIZE_MAPPING: { [s: string]: string } = {
  small: "Small",
  medium: "Medium",
  large: "Large",
};

export const RING_SIZES = ["small", "medium", "large"];

export const PROJECT_OPTIONS_TRY_ON: ProjectOption[] = [
  "try_on:furnitures",
  "try_on:glasses",
  "try_on:luggages",
  "try_on:purses",
  "try_on:rings",
  "try_on:vehicles",
  "try_on:necklaces",
  "try_on:image_tracking"
];

export const PROJECT_OPTIONS_VENUE: ProjectOption[] = ["venue"];

export const PROJECT_OPTIONS: ProjectOption[] = [
  ...PROJECT_OPTIONS_TRY_ON,
  ...PROJECT_OPTIONS_VENUE,
];

export const PROJECT_OPTIONS_MAPPING: Record<ProjectOption, string> = {
  "try_on:rings": "Rings",
  "try_on:vehicles": "Vehicles",
  "try_on:glasses": "Glasses",
  "try_on:furnitures": "Furniture",
  "try_on:luggages": "Luggages",
  "try_on:purses": "Purses",
  "try_on:necklaces": "Necklaces",
  "try_on:image_tracking": "Image Tracking",
  venue: "Venue",
  venue_v2: "Venue V2"
};

export const ASSET_UPLOAD_SIZE_LIMIT = {
  BYTES: 104857600,
  MB: 100,
};

export const SHARED_LINKS_KEYS = {
  RINGS: {
    IMAGES: "THUMBNAIL",
    TEXT: "BRAND/SLOGAN",
  },
  MODEL_VIEWER: {
    IMAGES: "THUMBNAIL" || "QR_IMAGE",
    TEXT: "BRAND/SLOGAN" || "QR_COPY",
  },
};

export const ACCEPTED_TRY_ON_2D_EXTENSIONS = ["png"];
export const ACCEPTED_TRY_ON_3D_EXTENSIONS = ["glb"];
export const ACCEPTED_TRY_ON_EXTENSIONS = [
  ...ACCEPTED_TRY_ON_2D_EXTENSIONS,
  ...ACCEPTED_TRY_ON_3D_EXTENSIONS,
];

// reusable form validations
export const VALIDATIONS = {
  LATITUDE: yup.number().min(-90).max(90),
  LONGITUDE: yup.number().min(-180).max(180),
  DEGREES: yup.number().min(0).max(360),
  DATE_IN_FUTURE: yup.date().nullable().typeError('invalid date').min(new Date().toLocaleDateString(), 'cannot be a date in the past'),
}
