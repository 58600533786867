import React, {useEffect, useState} from "react";
import type { FC } from "react";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {
  Box,
  Link,
  Divider,
  Drawer,
  Button,
  Hidden,
  Typography, IconButton, Popover, ListItemButton, List, ListItemText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import NavSection from "../NavSection";
import UsersIcon from "../icons/Users";

import { useGetOrgByIdQuery } from "../../store/services/orgs";
import { User } from "../../types/user";
import {useParams} from "react-router";
import {
  CalendarToday,
  ChevronLeft, ChevronRight,
  CloudDownload, Dashboard,
  Schedule,
  ShareLocation, StreetviewOutlined,
  ViewInAr
} from "@mui/icons-material";
import {useGetProjectsQuery} from "../../store/services/projects";
import {Project, ProjectType} from "../../types/project";
import Logo from "../common/Logo";

type RoleSection = {
  title: string;
  items: { title: string; path: string; icon?: JSX.Element }[];

};

const venueType: ProjectType = 'venue_v2';

function buildMenu({
                          orgName,
                          orgId,
                          projectId,
                          filters = [],
                        }: {
  orgName: string;
  orgId: string;
  projectId: string | undefined;
  filters?: string[] | undefined;
}) {
  return {
    title: "General",
    items: [
      {
        title: `Experience Manager`,
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/overview`,
        icon: <CalendarToday fontSize="small" />,
      },
      {
        title: `Experiences`,
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/experiences`,
        icon: <ViewInAr fontSize="small" />,
      },
      {
        title: `Zones`,
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/zones`,
        icon: <ShareLocation fontSize="small" />,
      },
      {
        title: `Schedules`,
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/schedules`,
        icon: <Schedule fontSize="small" />,
      },
      {
        title: `Users`,
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/users`,
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: `Download SDK`,
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/SDK`,
        icon: <CloudDownload fontSize="small" />,
      },
      {
        title: 'Spatial Captures',
        path: `/organizations/${orgId}/projects/${projectId}/experienceManager/spatialCaptures`,
        icon: <StreetviewOutlined fontSize="small" />,
      },
    ].filter(item => !filters.includes(item.title)),
  };
}

const DashboardSidebarV2: FC<{
  onMobileClose: () => void;
  openMobile: boolean;
  user: User;
  width: string;
  orgId: string | undefined;
}> = ({ width, ...props }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { paramProjectId } = useParams();
  const navigate = useNavigate();

  const { onMobileClose, openMobile } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { error, data } = useGetOrgByIdQuery({
    id: props.user.orgId,
    include: ["tier", "settings"],
  });

  const { data: projectsData } = useGetProjectsQuery({
    orgId: props.user.orgId,
  });

  const [selectedProject, setSelectedProject] = useState<Project | undefined>();
  const [roleSections, setRoleSections] = useState<RoleSection[]>([]);


  if (error != null) {
    enqueueSnackbar("There was an issue getting the Organization", {
      variant: "error",
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (error == null && data?.data) {
      if (selectedProject) {
        setRoleSections(
          [buildMenu({
            orgId: data.data.id,
            orgName: data.data.name,
            projectId: paramProjectId,
          })]
        );
      } else {
        setRoleSections(
          [buildMenu({
            orgId: data.data.id,
            orgName: data.data.name,
            projectId: paramProjectId,
            filters: ['Experience Manager', 'Experiences', 'Zones', 'Schedules'],
          })]
        );
      }
    }
  }, [selectedProject, data, error, paramProjectId]);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectProject = (project: Project) => {
    setSelectedProject(project);
    setAnchorEl(null);
    const newPathName = location.pathname.replace(`projects/${selectedProject?.id}`, `projects/${project.id}`);
    navigate(newPathName);
  }

  useEffect(() => {
    if (!selectedProject && paramProjectId) {
      setSelectedProject(projectsData?.data?.find(project => project.id === paramProjectId));
    }
  }, [selectedProject, paramProjectId, projectsData?.data]);
  
  let content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button sx={{px: 3.5, py: 2, display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center'}}
        onClick={handleClick}>
          <Dashboard sx={{mr: 1}} /> {selectedProject?.name || 'Select Project'} <ChevronRight sx={{ml: 'auto'}}/>
        </Button>
        <Popover
          id={id}
          sx={{maxHeight: '50%'}}
          open={open}   anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <List component="nav" sx={{paddingBottom: 0, paddingTop: 0, minWidth: '200px'}}>
            {projectsData?.data?.filter(project => project.type === venueType)?.map((project) =>
              <ListItemButton
                  key={project.id}
                selected={project.id === selectedProject?.id}
                onClick={() => {
                  selectProject(project);
                }}
              >
                <ListItemText primary={project.name} />
              </ListItemButton>)
            }
          </List>
        </Popover>
      </Box>

      <Divider />

      <Box sx={{ p: 2 }}>
        {roleSections.map((section) => (
          <NavSection
            hideTitle
            key={section.title}
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </Box>

      <Divider sx={{mt: 'auto'}} />

      <Box sx={{ p: 2, mt: 1 }}>
        <Typography color="textPrimary" variant="subtitle2">
          Need help?
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Reach out to our support
        </Typography>
        <Button
          color="primary"
          component={RouterLink}
          fullWidth
          sx={{ mt: 2 }}
          to="/contact"
          variant="outlined"
        >
          Contact
        </Button>

        <Box sx={{ mt: 3 }}>
          <Link component={RouterLink} to="/terms" underline="hover">
            <Typography color="textSecondary" sx={{ my: 1.5 }}>
              Terms of Service
            </Typography>
          </Link>
          <Link component={RouterLink} to="/privacy" underline="hover">
            <Typography color="textSecondary" sx={{ my: 1.5 }}>
              Privacy Notice
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width,
            },
          }}
          variant="temporary"
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between', pl:3.5, pt:2, mb:2}}>
            <RouterLink style={{display: 'flex', alignItems: 'center'}} to={`${props.orgId ? `/organizations/${props.orgId}` : '/'}`}>
              <Logo
                name={true}
                sx={{
                  height: 20,
                  width: "inherit",
                }}
              />
            </RouterLink>
            <IconButton sx={{mr: 2}} onClick={() => onMobileClose()}>
              <ChevronLeft />
            </IconButton>
          </Box>
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width,
            },
          }}
          variant="persistent"
        >
          <Box sx={{display: 'flex', alignItems: 'center', pl:3.5, pt:2, mb: 2}}>
            <RouterLink style={{display: 'flex', alignItems: 'center'}} to={`${props.orgId ? `/organizations/${props.orgId}` : '/'}`}>
              <Logo
                name={true}
                sx={{
                  height: 20,
                  width: "inherit",
                }}
              />
            </RouterLink>
          </Box>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebarV2;
