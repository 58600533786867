import { createApi } from "@reduxjs/toolkit/query/react";

import { serverConfig } from "../../config";
import { ApiResponse } from "../../types/response";
import { SharedAnchor, SharedAnchorFormValues } from "../../types/sharedAnchor";
import { axiosBaseQuery } from "./fetch";

const TAG = "SharedAnchors";
const LIST = "LIST";

export const sharedAnchorsApi = createApi({
  reducerPath: "sharedAnchorsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getSharedAnchorById: builder.query<
      ApiResponse<SharedAnchor>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `venue/shared_anchors/${id}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getSharedAnchors: builder.query<
      ApiResponse<SharedAnchor[]>,
      { environmentId?: string }
    >({
      query: ({ environmentId }) => {
        let url = `venue/shared_anchors`;
        if (environmentId) url += `?environmentId=${environmentId}`;
        return { url, method: "GET" };
      },
      providesTags: (result, err, queries) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createSharedAnchor: builder.mutation<
      ApiResponse<SharedAnchor>,
      {
        data: SharedAnchorFormValues;
      }
    >({
      query: ({ data }) => ({
        url: `venue/shared_anchors`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateSharedAnchor: builder.mutation<
      ApiResponse<SharedAnchor>,
      { id: string; data: Partial<SharedAnchor> }
    >({
      query: ({ id, data }) => ({
        url: `venue/shared_anchors/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: result.data.id },
              { type: TAG, id: LIST },
            ]
          : [],
    }),

    deleteSharedAnchor: builder.mutation<
      ApiResponse<SharedAnchor>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `venue/shared_anchors/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetSharedAnchorByIdQuery,
  useGetSharedAnchorsQuery,
  useCreateSharedAnchorMutation,
  useUpdateSharedAnchorMutation,
  useDeleteSharedAnchorMutation,
} = sharedAnchorsApi;
